@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'primeng/resources/themes/aura-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

/* You can add global styles to this file, and also import other style files */
// === RESET === //
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

body {
  margin: 0; // 1
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba(#000, 0); // 4
  font-size: 16px;
}

body {
  font-family: 'Raleway', sans-serif !important;
  color: #3d3d3c;
}

.logotext {
  line-height: 20px;
}

.logoTextFresh {
  margin-top: 15px;
  font-size: 36px;
  color: #76b82a;
  letter-spacing: 5px;
  font-weight: 600;
}
.logoTextProjects {
  font-size: 20px;
  letter-spacing: 5px;
  margin-top: 6px;
  color: #191919;
}

.spinner-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75px;
  height: 75px;
  margin: -37px 0 0 -37px;
}

.spinner-image-spin {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.btn .spinner-border {
  margin-left: 12px;
}

.error {
  color: rgb(156, 21, 21);
}

.register {
  color: darkgray;
  font-size: 0.9em;
  cursor: pointer;
}

.register:hover {
  color: #eb6209;
}

.starterText {
  color: #eb6209;
}

.reset {
  cursor: pointer;
}

.btn-outline-starter {
  color: #eb6209;
  border-color: #eb6209;
}

.btn-outline-starter:hover {
  color: white;
  background-color: #eb6209;
}

.contracts-badge {
  background-color: #3c3c3b;
  font-size: 20px;

  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 150px;
  margin: 0 auto;
}

.contracts-bg .register:hover {
  color: #3c3c3b;
}

a.register {
  text-decoration: none;
}

.contracts-bg .btn-outline-starter {
  color: #3c3c3b;
  border-color: #3c3c3b;
}

.contracts-bg .btn-outline-starter:hover {
  color: white;
  background-color: #3c3c3b;
}

.contracts-bg .starterText {
  color: #3c3c3b;
}

.helper-lg {
  font-size: 20px;
}

.input-group {
  margin-bottom: 0.25rem;
}

.formgroup {
  display: flex;
  flex-direction: column;
}

.form-input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.form-control {
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 11px 16px;

  &::placeholder {
    color: #bbb;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
}

.form-label {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: block;
}

.forgot {
  color: #666;
  cursor: pointer;
}

.forgot-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > *:last-child {
    cursor: pointer;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-divider {
  margin-bottom: 1rem;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  color: #000;
}

.title-description {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
}

.general-error {
  text-align: center;
}

.reset {
  text-align: center;
  margin-top: 16px;
}

.hide-mobile {
  display: block;
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 760px) {
  .hide-mobile {
    display: none !important;
  }
  .show-mobile {
    display: flex;
  }
}

.mobile-screen-warning {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .mobile-screen-warning-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    text-align: center;

    .mobile-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      border-bottom: 1px solid #ddd;

      .logo {
        padding: 14px;
      }
    }
    .mobile-image {
      position: relative;
      display: block;
      width: 100%;
      height: 180px;
      padding: 24px;
      padding-bottom: 0px;
      .mobile-image-bubble {
        width: 100%;
        height: 100%;
        background: #f6f8fc;
        border-radius: 32px;
      }
      img {
        position: absolute;
        bottom: 0;
        left: calc(50% - 314px / 2);
      }
    }

    .mobile-title {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.1px;
      margin-top: 32px;
      margin-bottom: 24px;
      padding: 0px 32px;
    }

    .mobile-description {
      color: #666;

      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 0px 32px;
    }
  }
}
/*


<div class="mobile-screen-warning-container">
    <div class="mobile-header">
      <div class="logo">
        <img src="assets/fp-logo-horizontal.svg" />
      </div>
    </div>
    <div class="mobile-image">
    <div class="mobile-image-bubble"></div>
      <img src="assets/fp-small-screen.png" />
    </div>
    <div class="mobile-title">Fresh Projects is not optimised for use on small screens</div>
    <div class="mobile-description">For an improved experience, use a bigger screen to access this page.</div>
  </div>

  */

.message-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-top: 16px;
}

.message-success {
  color: #76b82a;
  font-size: 16px;
  font-weight: 500;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff;
  background-color: #0891b2;
  border-color: #0891b2;
  padding: 11px 16px;
  border-radius: 8px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #76767e;
  background-color: #f2f2f3;
  border-color: #f2f2f3;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  color: #64748b;
  background-color: #fff;
  border-color: #cbd5e1;
  padding: 11px 16px;
  border-radius: 8px;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #76767e;
  background-color: #f2f2f3;
  border-color: #f2f2f3;
}

.support-helper {
  margin-top: 24px;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  a {
    text-decoration: none;
    color: #0891b2;
  }
}

.back-to-login {
  margin-top: 32px;
  color: #76767e;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

.form-error.invalid-feedback {
  display: block;
  margin-top: 4px;
}

.title.title-error {
  color: #dc2626;
}

.span-email {
  font-weight: bold;
}

table.fp-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 16px;
  font-family: Arial, sans-serif;
  margin-top: 16px;
}

table.fp-table thead {
  background-color: #79b935;
}

table.fp-table thead tr th {
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #efefef;
}

table.fp-table tbody tr {
  border-bottom: 1px solid #79b935;
}

table.fp-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

table.fp-table tbody tr:hover {
  background-color: #d4edda;
}

table.fp-table tbody td {
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #3d3d3c;
}

// Primeng fixes
.ops-column .col-header .p-input-icon {
  margin-top: -10px;
}

.account-status-badge {
  font-size: 13px;
  padding: 2px 12px;
}

// extend the badge class to add custom styles
.account-status-Active {
  @extend .account-status-badge;
  background-color: rgb(167, 243, 208);
}

.account-status-Hold {
  @extend .account-status-badge;
  background-color: rgb(239, 115, 115);
}

.account-status-Onboarding {
  @extend .account-status-badge;
  background-color: rgb(115, 154, 239);
}

.account-status-Test {
  @extend .account-status-badge;
  background-color: rgb(247, 208, 38);
}
